import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { fontStyles } from '../constants/styles';

import ModuleWrapper from './ModuleWrapper';


const Wrapper = styled(ModuleWrapper)`
  ${fontStyles.body};
`;


const PageDescription = ({ children, ...props }) => (
  <If condition={children}>
    <Wrapper {...props}>
      <div dangerouslySetInnerHTML={{ __html: children }} />
    </Wrapper>
  </If>
);

PageDescription.propTypes = {
  children: PropTypes.node,
};

export default PageDescription;
