import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import { fluidPropTypes } from '../types';

import LargeTeaser from './LargeTeaser';


const EventRoomTeaser = ({ changesColorOnHover, hasBlendMode, intl: { formatMessage }, room, url }) => {
  const moreDetailsText = formatMessage({ defaultMessage: 'More Details', id: 'global.moreDetails' });
  const text = `${room.description}\n\n[${moreDetailsText}](${url})`;

  const block = {
    changesColorOnHover,
    hasBlendMode,
    image: room.featuredImage,
    imageUrl: url,
    slug: room.slug,
    text,
    title: room.name,
  };

  return (
    <LargeTeaser block={block} />
  );
};

EventRoomTeaser.propTypes = {
  changesColorOnHover: PropTypes.bool,
  hasBlendMode: PropTypes.bool,
  intl: intlShape,
  room: PropTypes.shape({
    description: PropTypes.string.isRequired,
    featuredImage: PropTypes.shape({
      id: PropTypes.string,
      fluid: fluidPropTypes,
    }).isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
  url: PropTypes.string.isRequired,
};

export default injectIntl(EventRoomTeaser);
