import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { SPACING_VERTICAL } from '../constants/spacings';
import { fontStyles } from '../constants/styles';
import { isInternalURI } from '../helpers/location';
import { customMarkdown } from '../helpers/markdown';

import ExternalLink from './ExternalLink';
import LocalisedLink from './LocalisedLink';


const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${SPACING_VERTICAL.s};
  ${fontStyles.bodySmall};

  > * + * {
    margin-top: 1rem;
    text-align: center;
  }

  & + * {
    ${SPACING_VERTICAL.s} !important;
  }
`;


const Information = ({ buttonLabel, buttonUrl, text }) => {
  if (!(text || (buttonLabel && buttonUrl))) {
    return null;
  }

  return (
    <Wrapper>
      <If condition={buttonLabel && buttonUrl}>
        <Choose>
          <When condition={isInternalURI(buttonUrl)}>
            <LocalisedLink isButton to={buttonUrl}>{buttonLabel}</LocalisedLink>
          </When>
          <Otherwise>
            <ExternalLink href={buttonUrl} isButton>{buttonLabel}</ExternalLink>
          </Otherwise>
        </Choose>
      </If>
      <If condition={text}>
        {customMarkdown(text)}
      </If>
    </Wrapper>
  );
};

Information.propTypes = {
  buttonLabel: PropTypes.string,
  buttonUrl: PropTypes.string,
  text: PropTypes.string,
};

export default Information;
