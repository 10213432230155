import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import BlockRouter from '../components/BlockRouter';
import EventRoomTeaser from '../components/EventRoomTeaser';
import GradientWrapper from '../components/GradientWrapper';
import HelmetMichelberger from '../components/HelmetMichelberger';
import Information from '../components/Information';
import Layout from '../components/Layout';
import PageDescription from '../components/PageDescription';
import PageTitle from '../components/PageTitle';


const EventRooms = ({ data, location, pageContext }) => {
  const { languageSwitch } = pageContext;
  const footerData = data.allDatoCmsFooter.edges[0].node;
  const {
    body, description, gradient, infoButtonLabel, infoButtonUrl, infoText, name,
    rooms, seoMetaTags, slug,
  } = data.allDatoCmsEventRoomsPage.edges[0].node;

  return (
    <Layout languageSwitch={languageSwitch} location={location}>
      <GradientWrapper footerData={footerData} gradient={gradient} languageSwitch={languageSwitch}>
        <HelmetMichelberger seo={seoMetaTags} />
        <PageTitle>{name}</PageTitle>
        <PageDescription isSmall>{description}</PageDescription>
        <Information buttonLabel={infoButtonLabel} buttonUrl={infoButtonUrl} text={infoText} />
        <For each="block" index="index" of={body}>
          <Choose>
            <When condition={block.model.apiKey === 'event_room_teaser'}>
              <With room={rooms.find((r) => r.id === block.eventRoom.id)}>
                <If condition={room}>
                  <EventRoomTeaser
                    key={block.id}
                    changesColorOnHover={block.changesColorOnHover}
                    hasBlendMode={block.hasBlendMode}
                    room={room}
                    url={`/${slug}/${room.slug}`}
                  />
                </If>
              </With>
            </When>
            <Otherwise>
              <BlockRouter key={block.id} block={block} index={index} />
            </Otherwise>
          </Choose>
        </For>
      </GradientWrapper>
    </Layout>
  );
};

EventRooms.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default EventRooms;

export const query = graphql`
  query EventRoomsPage($locale: String!) {
    allDatoCmsEventRoomsPage(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          name
          slug
          description
          infoButtonLabel
          infoButtonUrl
          infoText
          ...GatsbyDatoCmsEventRoomsPageBody
          rooms {
            id
            name
            description
            slug
            featuredImage {
              fluid(maxWidth: 2400, maxHeight: 1600, imgixParams: { fm:"jpg", auto:"format" }) {
                ...GatsbyDatoCmsFluid
              }
              url
            }
          }
          seoMetaTags {
            ...GatsbyDatoCmsSeoMetaTags
          }
          gradient {
            color {
              hex
            }
          }
        }
      }
    }
    allDatoCmsFooter(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          ...footerFields
        }
      }
    }
  }
`;
